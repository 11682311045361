// @font-face {
//   font-family: 'Signifier';
//   src: url('./fonts/Signifier-Light.otf') format('opentype'),
//     url('./fonts/signifier-web-light.woff2') format('woff2'),
//     url('./fonts/SignifierApp-Light.ttf') format('truetype');
//   font-style: normal;
//   font-weight: 600;
// }

// @font-face {
//   font-family: 'Signifier';
//   src: url('./fonts/Signifier-LightItalic.otf') format('opentype'),
//     url('./fonts/signifier-web-light-italic.woff2') format('woff2'),
//     url('./fonts/SignifierApp-LightItalic.ttf') format('truetype');
//   font-style: italic;
//   font-weight: 200;
// }

.App {
  height: 100vh;
  display: flex;
  align-items: center;
}

.marquee-container {
  overflow-x: hidden !important;
  display: flex !important;
  flex-direction: row !important;
  position: relative;
  width: 100%;

  &:hover div {
    animation-play-state: var(--pause-on-hover);
  }

  &:active div {
    animation-play-state: var(--pause-on-click);
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;

  @mixin gradient {
    background: linear-gradient(to right, var(--gradient-color));
  }

  &::before,
  &::after {
    @include gradient;
    content: '';
    height: 100%;
    position: absolute;
    width: var(--gradient-width);
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }
}

.marquee {
  background-color: #ede7e1;
  flex: 0 0 auto;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: scroll var(--duration) linear var(--delay) var(--iteration-count);
  animation-play-state: var(--play);
  animation-delay: var(--delay);
  animation-direction: var(--direction);
  --delay: 0s;
  --iteration-count: infinite;

  @keyframes scroll {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}

.text-element {
  color: #45221d;
  font-family: 'Signifier';
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 36px;
  text-align: center;
  margin-right: 20px;
}

@media screen and (max-width: 768px) {
  .text-element {
    font-size: 21px;
    line-height: 27px;
  }
}
